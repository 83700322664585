<template>
    <div class="cadre">
        <router-view
            v-if="charge && $dossier.id"
            :compte="compte"
            :compte-charger="compteCharger"
        >
        </router-view>
        <div v-if="!charge">
            <img src="@/assets/img/courtisia-logo-inverse.svg" class="loader">
        </div>
    </div>
</template>

<script>
  import api from '@/api'

  export default {
    name: 'Main',
    beforeRouteEnter(to, from, next) {
      if (process.env.NODE_ENV === 'development') {
        api.configurer(null)
        next()
        return
      }
      api.jetonRecuperer()
        .then(({data}) => {
          api.configurer(data.jeton)
          next()
        })
    },
    created() {
      this.compteCharger()
        .then(() => api.proceduresCharger(this.$dossier.id).then(() => true, () => false))
        .then((procedureExiste) => {
            if(procedureExiste) {
              this.$router.push({name: 'ProcedureGerer'})
              return;
            } else {
              this.$router.push({name: 'ProcedureCreer'})
            }

          this.charge = true
        })
      this.$root.$on('compteRafraichir', (cb) => {
        this.compteCharger().then(() => {
          if(cb){
            cb()
          }
        })
      })
    },
    data() {
      return {
        charge: false,
        compte: null,
      }
    },
    methods: {
      compteCharger() {
        return api.compteCharger().then(d => {
          this.$compte.champsModifier(d.data)
          this.compte = this.$compte
          this.charge = true
          return true
        })
      }
    }
  }
</script>

<style lang="scss" scoped>

    @import "../../node_modules/courtisia-style/scss/colors.scss";

    .base {
        background: $gray-lighter !important;
    }

    .container {
        padding: 20px;
    }

    .cadre {
        height: auto !important;
        background: white;
        border: none;
    }

    h2 {
        font-weight: 100;
    }

    .block-documents {
        margin-bottom: 20px;
    }

    .menu-brique {
        padding: 20px;
        border: 1px solid $gray-light;
        display: flex;
        align-items: center;
    }

    .cadre-pied {
        border-top: 1px solid $gray-light;
    }

    .coordonee-incomplete, .signer-action {
        margin-right: 0;
        margin-left: auto;
    }

    .signataire-nom {
        display: flex;
        align-items: center;
    }

    .modal-commande {
        text-align: center;
    }

    .signatures-solde {
        display: flex;
        justify-content: space-between;
        padding: 20px;
        margin-bottom: -20px;
        margin-top: 10px;
        background: white;
        width: 100%;
        margin-left: -20px;
        color: #009588;
        text-transform: uppercase;
    }
</style>
