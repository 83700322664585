import Vue from 'vue'
import App from './App.vue'

import VueRouter from 'vue-router'
Vue.use(VueRouter)

import { Compte, Dossier } from '@/utils'
Vue.use(Compte)
Vue.use(Dossier)

import Modal from './components/Modal.vue'
Vue.component('Modal', Modal)

import router from '@/router'

Vue.config.productionTip = false

window.seVueApp = function(dossierId) {
  return new Vue({
    router,
    render: h => h(App, { props: {dossierId } }),
  });//.$mount('.vuejs_app')*/
}

if (document.querySelector('.signature-elec-app')) {
  window.seVueApp(process.env.VUE_APP_DOSSIER_ID).$mount('.signature-elec-app')
}
