<template>
    <div>
        <div v-if="!charge">
            <img src="../assets/img/courtisia-logo-inverse.svg" class="loader">
        </div>
        <div class="base cadre" v-if="charge">
            <div class="container cadre-content">
                <h2>
                    Vous êtes sur le point de créer une procédure de signature électronique (en partenariat avec YouSign)</h2>
                <div
                    class="alerte-container alerte--danger"
                    v-if="!$compte.se_coordonnees_valides"
                >
                    Les procédures électroniques nécessitent votre email et votre numéro de mobile. <br>
                    Veuillez vous rendre dans le module Paramètres de votre bureau Courtisia puis sous votre profil utilisateur afin de compléter vos coordonnées.
                </div>
                <div
                    class="alerte-container alerte--danger"
                    v-if="!signatairesValide"
                >
                    La procédure de signature électronique nécessite l'email et le numéro mobile de chacun des signataires. <br>
                    Veuillez configurer les coordonnées emprunteurs dans le formulaire du dossier.
                </div>

                <div v-else
                    class="alerte-container alerte--primary"
                >
                    Vos coordonnées : {{ $compte.email }} - {{ $compte.mobile }}
                </div>
                <div class="cadre">
                    <div class="cadre-tete cadre-tete--menu">
                        Signature des documents suivants:
                    </div>
                    <div class="cadre-content">
                        <div
                            class="menu-brique"
                            v-for="document in documents"
                            :key="document.id"
                            v-if="documents.length > 0"
                        >
                            <i class="far fa-file fa-2x"></i> &nbsp; {{document.lib}}
                        </div>

                        <div
                            class="menu-brique"
                            v-if="documents.length == 0"
                        >
                            Aucun document à signer
                        </div>
                    </div>
                </div>

                <div class="cadre"
                    v-if="documents.length > 0">
                    <div class="cadre-tete cadre-tete--menu">
                        Signataires
                    </div>
                    <div class="cadre-content">
                        <div
                            class="menu-brique"
                            v-for="signataire in signataires"
                            :key="signataire.id"
                        >
                            <template>
                                <span class="signataire-nom">
                                    {{signataire.prenom}} {{signataire.nom}}
                                </span>
                                <span
                                    class="signataire-coordonnees">
                                    <span v-if="signataire.valide">{{ signataire.email }} <br> {{ signataire.mobile }} </span>

                                    <span
                                        class="text-error coordonees-incomplete"
                                        v-if="!signataire.valide">
                                        <i class="fas fa-times-circle"></i> &nbsp; Coordonnées incomplètes
                                    </span>
                                </span>
                            </template>
                        </div>
                    </div>
                </div>

                <div class="cadre" v-if="notice_checker">
                    <div class="cadre-tete cadre-tete--menu">
                        Question légale
                    </div>
                    <div class="cadre-content">
                        <p>
                            <b> Les emprunteurs estiment leurs connaissances en matières d'opérations bancaires : </b>
                        </p>

                        <div class="choices">
                            <label class="checkcontainer">
                                SUFFISANTES
                                <input 
                                    type="radio"
                                    value="true" 
                                    v-model="configurations.connaissances">
                                <span class="checkmark"></span>
                            </label>
                            <label class="checkcontainer">
                                INSUFFISANTES
                                <input 
                                    type="radio"
                                    value="false" 
                                    v-model="configurations.connaissances">
                                <span class="checkmark"></span>
                            </label>
                        </div>
                    </div>
                </div>

                <div class="signatures-solde">
                    <div>Crédit requis: {{signataires.length}}</div>
                    <div>Solde Actuel: {{$compte.se_credits}}
                    </div>
                </div>
            </div>
            <div
                class="cadre-pied background-white"
            >
                <button
                    type="button"
                    class="btn btn-default"
                    :disabled="!(signatairesValide && $compte.se_coordonnees_valides) || creditsRequis || creation"
                    @click="procedureCreer"
                    v-if="documents.length > 0"
                >
                    {{creation ? 'Création de la procédure...' : 'Démarrer la procédure'}}
                </button>
                <button
                    v-if="$compte.administrateur"
                    type="button"
                    class="btn btn-default"
                    @click="modalContexte = 'ModalCommande'"
                >
                    Créditer mon compte
                </button>
            </div>

            <component
                :is="modalContexte"
                :close="closeModal"
            ></component>

            <modal v-if="coordonnees_erreur">
                <template slot="header"> Coordonnées incorrectes </template>
                <template slot="content">
                    <p> {{ coordonnees_erreur }} </p>
                </template>
                
                <template slot="footer">
                    <button
                        type="button"
                        class="btn btn-default"
                        @click="coordonnees_erreur=false"
                    >
                        Fermer
                    </button>
                </template>
            </modal>

        </div>

    </div>
</template>

<script>
  import api from '@/api'
  import ModalCommande from "./ModalCommande.vue";
  import ModalProcedureExistante from "./ModalProcedureExistante.vue";
  import ModalAvenant from "./ModalAvenant.vue";
  import ModalDroitManquant from "./ModalDroitManquant.vue";

  export default {
    name: 'ProcedureCreer',
    components: {
      ModalDroitManquant,
      ModalAvenant,
      ModalProcedureExistante,
      ModalCommande
    },
    props: {
      compteCharger: Function
    },
    data() {
      return {
        documents: [],
        signataires: [],
        configurations: { connaissances: true },
        packs: [],
        charge: false,
        modalContexte: null,
        signatairesValide: true,
        notice_checker: false,
        creation: false,
        coordonnees_erreur: false
      }
    },
    created() {
      this.signatureContextualiser().then(() => {
        this.charge = true
        this.modalContexteInitialiser()
      })
    },
    computed: {
      creditsRequis: function () {
        return this.$compte.se_credits <= 0 || this.$compte.se_credits < this.signataires.length
      }
    },
    methods: {
      signatureContextualiser() {
        return api.signaturesContextualiser(this.$dossier.id)
            .then((d) => {
              const {documents, signataires, signataires_valide, notice_checker} = d.data
              this.documents = documents
              this.signataires = signataires

              if(this.$compte.client) {
                this.signataires = this.signataires.filter((s) => s.type !== 'COURTIER')
              }

              this.signatairesValide = signataires_valide
              this.notice_checker = notice_checker
              return true
            })
      },
      modalContexteInitialiser() {
        if (this.existe) {
          this.modalContexte = 'ModalProcedureExistante'
          return;
        }

        /* si procédure impossible et compte non administrateur (admin / mandant) */
        if ((
                !this.$compte.se_avenant_signe
                || this.creditsRequis
            )
            && !this.$compte.administrateur) {
          this.modalContexte = 'ModalDroitManquant'
          return;
        }

        if (this.creditsRequis) {
          this.modalContexte = 'ModalCommande'
          return;
        }
        
        if (!this.$compte.se_avenant_signe) {
          this.modalContexte = 'ModalAvenant'
          return;
        }
      },
      procedureCreer(e) {
        e.preventDefault()
        this.creation = true

        let data = {
            signataires : this.signataires,
            configurations : this.configurations
        }
        
        api.procedureCreer(this.$dossier.id, data)
            .then(() => {
              this.creation = false
              this.$router.push({name: 'ProcedureGerer'})
            }, (error) => {
                if (error.response.data
                    && error.response.data.erreurs
                    && error.response.data.erreurs.coordonnees) {
                    this.coordonnees_erreur=error.response.data.erreurs.coordonnees;
                    this.creation = false
                }
            })
      },
      closeModal() {
        this.modalContexte = null
        this.compteCharger()
            .then(() => {
              this.modalContexteInitialiser()
            })
      }
    },
  }
</script>

<style lang="scss" scoped>

    @import "../../node_modules/courtisia-style/scss/colors.scss";

    .base {
        background: $gray-lighter !important;
    }

    .container {
        padding: 20px;
    }

    .cadre {
        height: auto !important;
        background: white;
        margin-bottom: 20px;
    }

    h2 {
        font-weight: 100;
    }

    .menu-brique {
        padding: 20px;
        border: 1px solid $gray-light;
        display: flex;
        align-items: center;
    }

    .cadre-pied {
        border-top: 1px solid $gray-light;
    }

    .coordonees-incomplete, .signer-action {
        margin-right: 0;
        margin-left: auto;
    }

    .signataire-nom {
        flex: 1;
    }

    .signataire-coordonnees {
        flex-basis: 30%;
    }

    .modal-commande {
        text-align: center;
    }

    .signatures-solde {
        display: flex;
        justify-content: space-between;
        padding: 20px;
        margin-bottom: -20px;
        margin-top: 10px;
        background: white;
        width: 100%;
        margin-left: -20px;
        color: #009588;
        text-transform: uppercase;
    }

    .alerte-container {
        margin-bottom: 10px;
    }

    .choices {
        padding: 25px 15px;
    }

    /* Customize the label (the container) */
    .checkcontainer {
        float : left;
        margin-right: 15px;
        display: block;
        position: relative;
        padding-left: 35px;
        margin-bottom: 12px;
        cursor: pointer;
        font-size: 15px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;

        input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
        }
    }

    /* Create a custom radio button */
    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 20px;
      width: 20px;
      background-color: #eee;
      border-radius: 50%;
    }

    /* On mouse-over, add a grey background color */
    .checkcontainer:hover input ~ .checkmark {
      background-color: #ccc;
    }

    /* When the radio button is checked, add a blue background */
    .checkcontainer input:checked ~ .checkmark {
      background-color: #105BFB;
    }

    /* Create the indicator (the dot/circle - hidden when not checked) */
    .checkmark:after {
      content: "";
      position: absolute;
      display: none;
    }

    /* Show the indicator (dot/circle) when checked */
    .checkcontainer input:checked ~ .checkmark:after {
      display: block;
    }

    /* Style the indicator (dot/circle) */
    .checkcontainer .checkmark:after {
      top: 6px;
      left: 6px;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: white;
    }
</style>
