export const Compte = {
  install(Vue, options) {

    Vue.prototype.$compte = {

      champsModifier(champs) {
        Object.keys(champs).forEach((k) => this[k] = champs[k])
      }
    }
  }
}

export const Dossier = {
  install(Vue) {

    Vue.prototype.$dossier = {
      id: null
    }
  }
}

