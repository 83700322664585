<template>
    <modal>
        <template slot="header">Compléter la commande</template>
        <template slot="content">
            <p>Votre première commande nécessite la signature d'un avenant.</p>
            <br>
            <p>Cette étape ne sera plus nécessaire lors de vos prochaines commandes.</p>
            <br>
            <p>
                Vous pouvez procéder à la signature de l'avenant électroniquement directement, 
                ou via l'email que recevrez prochainement
            </p>
        </template>
        <template slot="footer">
            <a
                class="btn btn-transparent btn-large"
                target="_blank"
                :disabled="!urlAvenant"
                :href="urlAvenant"
            >
                Signer
            </a>
        </template>
    </modal>
</template>

<script>

  import api from '@/api'

  export default {
    name: 'ModalAvenant',
    props: {
      close: Function,
    },
    data() {
      return {
        urlAvenant: null,
        intervalID: null,
      }
    },
    created() {
      this.signatureAvenantVerifier()
    },
    beforeDestroy() {
      clearInterval(this.intervalID)
    },
    methods: {
      signatureAvenantVerifier() {
        clearInterval(this.intervalID)
        api.avenantSignatureRecuperer().then((d) => {
          this.urlAvenant = d.data.url;
          this.intervalID = setInterval(() => {
            api.compteCharger().then((d) => {
              this.$compte.champsModifier(d.data)
              if (d.data.se_avenant_signe) {
                this.close()
              }
            })
          }, 5000)
        })
      },
    }
  }
</script>

<style lang="scss" scoped>
    a.btn {
        text-decoration: none;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    p {
        margin: 0 !important;
    }
</style>
