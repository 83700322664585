<template>
    <modal>
        <template slot="header">
            Une procédure existe déja
        </template>
        <template slot="content">
            <div>
                Une procédure est déjà en cours pour ce dossier
            </div>
        </template>
        <template slot="footer">
            <router-link
                tag="button"
                :to="{name: 'ProcedureGerer'}"
                class="btn btn-transparent btn-large"
            >
                Voir la procédure en cours
            </router-link>
        </template>
    </modal>
</template>

<script>
    export default {
      name: 'ModalProcedureExistante'
    }
</script>

<style lang="scss" scoped>

</style>
