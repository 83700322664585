import VueRouter from 'vue-router'
import ProcedureCreer from '@/views/ProcedureCreer'
import ProcedureGerer from '@/views/ProcedureGerer'
import Main from '@/views/Main'

const routes = [
  {
    path: '/',
    component: Main,
    redirect: { name: 'ProcedureGerer' },
    children: [
      {
        path: 'procedure-creer',
        name: 'ProcedureCreer',
        component: ProcedureCreer,
      },
      {
        path: 'procedure-gerer',
        name: 'ProcedureGerer',
        component: ProcedureGerer,
      },
    ]
  },
  { 
      path: "*",
      component: Main,
      redirect: { name: 'ProcedureGerer' }
  }
]

export default new VueRouter({
  routes // short for `routes: routes`
})
