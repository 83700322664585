<template>
    <modal
        :no-footer="true"
        class="modal"
    >
        <template slot="header">
            Créditer mon compte
        </template>
        <template slot="content">
            <div>
                <p> Crédits insuffisants pour réaliser la procédure de signature électronique. </p>
                <p> Vous devez contacter le responsable de votre réseau pour activer et créditer votre compte. </p>
            </div>
        </template>
    </modal>
</template>

<script>
    export default {
      name: 'ModalDroitManquant'
    }
</script>

<style lang="scss" scoped>
</style>
