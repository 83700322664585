<template>
    <modal>
        <template slot="header">
            Créditer mon compte
        </template>
        <template slot="content">
            <div>
                <div
                    class="alerte-container alerte--danger"
                    v-if="!$compte.se_coordonnees_valides"
                >
                    Les procédures électroniques nécessitent votre email et votre numéro de mobile. <br>
                    Veuillez vous rendre dans le module Paramètres de votre bureau Courtisia puis sous votre profil utilisateur afin de compléter vos coordonnées.
                </div>
                <div class="form-group"
                     :class="{'form-group--error': packError}"
                >
                    <label>Nombre de signatures *</label>
                    <select
                        class="form-control"
                        v-model="pack"
                    >
                        <option :value="null">Sélectionner</option>
                        <option
                            v-for="p in packs"
                            :key="p.id"
                            :value="p.id">{{p.lib}}
                        </option>
                    </select>
                    <small
                        class="text-error"
                        v-if="packError"
                    >Veuillez sélectionner un pack
                    </small>
                </div>
                <p>
                  <i>
                    Pour une commande supérieure à 1000 signatures, veuillez contacter le service commercial.
                    <a href="mailto:support@fletesia.com">support@fletesia.com</a>
                  </i>
                </p>

                <p v-if="$compte.se_avenant_signe" class="avenant">
                    Le présent avenant, qui vient compléter le contrat de vente, permet de formaliser la souscription de crédits de signatures électroniques, à utiliser via le logiciel COURTISIA.

                    <a :href="$compte.se_avenant_document"> Consulter l'avenant </a>

                    <label class="checkbox">
                        <input type="checkbox" v-model="cgu_valider"/>
                        <div class="checkmark"></div>
                        J'accepte les conditions générales de revente des packs de signature électronique.
                    </label>
                </p>

            </div>
        </template>
        <template slot="footer">
            <button
                type="button"
                class="btn btn-transparent"
                @click="close"
            >Annuler
            </button>
            <button
                type="button"
                class="btn btn-transparent"
                @click="crediterCompte()"
                :disabled="creation || !$compte.se_coordonnees_valides || ($compte.se_avenant_document && !cgu_valider)"
            >Créditer mon compte
            </button>
        </template>
    </modal>
</template>

<script>
  import api from '@/api'

  export default {
    name: 'ModalCommande',
    props: {
      close: Function
    },
    data() {
      return {
        pack: null,
        packError: false,
        packs: [],
        creation: false,
        cgu_valider: false
      }
    },
    created() {
      this.packsCharger()
    },
    methods: {
      packsCharger() {
        return api.packsCharger().then((d) => {
          this.packs = d.data
          return true
        })
      },
      crediterCompte() {
        this.packError = false
        if (this.pack) {
          this.creation = true;
          
          api.commandeCreer({pack_id: parseInt(this.pack)})
            .then(() => {
              this.$root.$emit('compteRafraichir')
              this.creation = false;
              this.close()
            }).catch(() => {
              this.creation = false;
            })

        } else {
          this.packError = true
        }
      },
    }
  }
</script>

<style lang="scss" scoped>
    .alerte-container {
        margin-bottom: 15px;
    }

    .avenant {
      margin-top: 15px;
      border: 1px solid #eee;
      padding: 15px;

      .checkbox {
        margin: 15px 0 7px;
      }
    }
</style>
