<template>
    <div class="courtisia-module signature-container">
        <router-view></router-view>
    </div>
</template>

<script>

  export default {
    name: 'app',
    props: {
      dossierId: String
    },
    created() {
      this.$dossier.id = this.dossierId
    },
    components: {}
  }
</script>

<style lang="scss">

    @import "assets/scss/main";
</style>
