import axios from 'axios'

class Api {

  constructor(url) {
    this.axios = axios.create({
      baseURL: url,
      transformResponse: [function (data, headers) {
        if (headers['content-type'] === 'application/json') {
          const fn = JSON.parse || JSON.decode
          return fn(data);
        }
        return data
      }],
      transformRequest: [function (data, headers) {
        try {
          return JSON.stringify(data)
        } catch {
          return JSON.encode(data)
        }
      }
      ],
    })
  }

  configurer(token, cb) {
    //token = '311ff989413948b25a4a22139d90366d'
    this.axios.defaults.headers.common['jeton'] = token || process.env.VUE_APP_DEFAULT_TOKEN
    /*this.axios.interceptors.response.use((response) => {
      return response
    }, (err) => {
      if (err.response.status === 403) {
        cb()
      }
      return Promise.reject(err)
    })*/
  }

  signaturesCharger(dossierId) {
    return this.axios.get(`/dossiers/${dossierId}/se-procedures`)
  }

  compteCharger() {
    return this.axios.get('/utilisateurs/compte')
  }

  procedureCreer(dossierId, data) {
    return this.axios.post(`/dossiers/${dossierId}/se-procedures`, data)
  }

  jetonRecuperer() {
    return this.axios.get('/utilisateurs/compte/jeton')
  }

  signaturesContextualiser(dossierId) {
    return this.axios.get(`/dossiers/${dossierId}/se-procedures/contextualiser`)
  }

  proceduresCharger(dossierId) {
    return this.axios.get(`/dossiers/${dossierId}/se-procedures`)
  }

  procedureAnnuler(dossierId) {
    return this.axios.delete(`/dossiers/${dossierId}/se-procedures`)
  }

  packsCharger() {
    return this.axios.get('/se-commandes/packs')
  }

  commandeCreer(pack) {
    return this.axios.post('/se-commandes', pack)
  }

  avenantSignatureRecuperer() {
    return this.axios.get('/se-commandes/avenant')
  }

}

const locationName = `${window.location.protocol}//${window.location.href.split('/')[2]}/api/v1/`
const apiUrl = (process.env.VUE_APP_API_URL) ? process.env.VUE_APP_API_URL : locationName

export default new Api(apiUrl)
